export default {
  partnerTitle: 'Партнер',
  partner_title: 'Партнер',
  webmaster: 'Web-мастер',
  clicks: 'Клики',
  registrations: 'Регистрации',
  requests: 'Заявки',
  declines: 'Отказы',
  approves: 'Одобренные',
  issues: 'Расходы',
  profit: 'Выручка',
  cr: 'CR',
  ar: 'AR',
  epc: 'EPC',
  target_events: 'Целевое действие',
  campaign: 'Метка',

  project: 'Проект',
  type: 'Постбэк',
  wmid: 'Web-мастер',
  postback: 'Постбэк',
  period: 'Период',
  cost: 'Ставка руб.',
  actions: 'Операции',

};
