
import Vue from 'vue';
import DatePicker from 'vue2-datepicker';

export default Vue.extend({
  name: 'ManageCostsItem',
  props: ['order', 'focused', 'index', 'data', 'isEditing'],
  data() {
    return {
      date: null,
      secondDate: null,
      datePlaceholder: '',
      inputs: {
        ...this.$props.data,
      },
      postback: {
        click: 'Клик',
        register: 'Регистрация',
        issue: 'Подписка',
      },
    };
  },
  mounted() {
    this.datePlaceholder = this.$props.data.start_date.slice(0, 10);
    this.datePlaceholder += this.$props.data.end_date ? ` - ${this.$props.data.end_date.slice(0, 10)}` : ' - Бессрочен';
  },
  methods: {
    changeDate(date) {
      if (!this.date) {
        this.date = date.toISOString();
      } else {
        this.secondDate = date.toISOString();
      }
    },
    submitDate() {
      const _first = this.formatDate(this.date || this.data.start_date);
      const _second = this.formatDate(this.secondDate);

      this.datePlaceholder = `${_first} - ${_second ? _second : 'Бессрочен'}`;
      this.date = null;
      this.secondDate = null;

      this.$emit('changeDate', _first, _second);
    },
    formatDate(date) {
      if (!date) return null;
      const _date = new Date(Date.parse(date));
      const year = _date.getFullYear();
      const month = String(_date.getMonth() + 1).padStart(2, '0');
      const day = String(_date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
  },
  // emits: ['edit'],
  components: {DatePicker},
});
