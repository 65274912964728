









































































































import RCP from '@/api/RCP';
import SelectField from '@/components/SelectField.vue';
import Vue from 'vue';

import XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import labels from './labels';
import DatePicker from 'vue2-datepicker';
import DatePickerCustom from '@/components/DatePicker.vue'
import ManageCostsItem from '@/components/manageCosts/ManageCostsItem.vue';


export default Vue.extend({
  name: 'ManageCostsTable',
  components: {SelectField, ManageCostsItem, DatePicker, DatePickerCustom},
  props: {
    items: {
      type: Array,
      required: true,
    },
    total: {
      type: Object,
      default() {
        return {};
      },
    },
    order: {
      type: Array,
    },
    project: {
      type: String,
    },
    partner: {
      type: String,
    },
    projectId: {
      type: Number,
    },
    partnerId: {
      type: Number,
    },
    wmids: {
      type: Array,
    },
  },
  data() {
    return {
      labels,
      localData: [] as any,
      filter: {
        name: '' as any,
        type: 'down',
      },
      isContextFocused: false,
      currentEditing: null,
      startDate: null as null | string,
      endDate: null as null | string,

      startWmDate: null as null | string,
      endWmDate: null as null | string,

      bet: null,
      date: null,
      secondDate: null,
      wmDate: null,
      secondWmDate: null,
      webmasterAdd: '',
      betAdd: 0,

      isTableOpen: false,
      openedMenu: '',
      download: {
        to: '',
        from: '',
      },

      datePlaceholder: 'Период',
      wmDatePlaceholder: 'Период',
      postback: {
        click: 'Клик',
        register: 'Регистрация',
        issue: 'Подписка',
      },
    };
  },
  mounted() {
    this.filter.name = this.order[0];
    this.localData = [...this.items];
    this.filtered();
  },
  methods: {
    setFilter(name, type) {
      this.filter = {
        name,
        type,
      };

      this.filtered();
    },
    filtered() {
      this.localData.sort((a: any, b: any) => {
        if (this.filter.type === 'up') {
          if (a[this.filter.name] > b[this.filter.name]) {
            return 1;
          } else if (b[this.filter.name] > a[this.filter.name]) {
            return -1;
          }

          return 0;
        }

        if (a[this.filter.name] < b[this.filter.name]) {
          return 1;
        } else if (b[this.filter.name] < a[this.filter.name]) {
          return -1;
        }

        return 0;
      });
    },
    downloadTable() {
      const wb = XLSX.utils.book_new();

      wb.Props = {
        Title: 'Table',
        Subject: 'Table',
        Author: 'Zaymigo',
        CreatedDate: new Date(),
      };

      wb.SheetNames.push('Table');

      const titles = ['Постбэк', 'Web-мастер', 'Начало', 'Окончание', 'Ставка'] as any;

      const wsData = [titles] as any;
      this.localData.forEach((item: any) => {
        let line: any;

        line = [
          item.type,
          item.wmid,
          item.start_date,
          item.end_date,
          item.cost,
        ];

        wsData.push(line);
      });

      const TotalKeys = Object.keys(this.total);
      if (TotalKeys.length > 0) {
        const line = ['Итого'] as any;

        this.order.forEach((title: any) => {
          if (typeof this.total[title] !== 'undefined') {
            line.push(this.total[title]);
          }
        });

        wsData.push(line);
      }

      wb.Sheets.Table = XLSX.utils.aoa_to_sheet(wsData);

      const wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        type: 'binary',
      });

      function s2ab(s) {
        const buf = new ArrayBuffer(s.length); // convert s to arrayBuffer
        const view = new Uint8Array(buf);  // create uint8array as viewer
        for (let i = 0; i < s.length; i++) {
          /* tslint:disable:no-bitwise */
          view[i] = s.charCodeAt(i) & 0xFF;
          /* tslint:enable:no-bitwise */
        } // convert to octet
        return buf;
      }

      saveAs(new Blob([s2ab(wbout)], {type: 'application/octet-stream'}), 'table.xlsx');
    },
    setFocused(index, inputs) {
      if (this.isContextFocused) {
        return;
      }
      if (this.currentEditing === null || this.currentEditing !== index) {
        this.currentEditing = index;
      } else {
        this.currentEditing = null;
        RCP({
          method: 'Wm.edit',
          params: {
            wmid: inputs.wmid,
            cost: inputs.cost,
            start_date: this.startDate || inputs.start_date,
            end_date: this.endDate,
            id: inputs.id,
            partner_id: inputs.partner_id,
            project_id: inputs.project_id,
          },
        });
        const _wmids = [] as any[];
        for (const item of this.items as any[]) {
          _wmids.push(item.id);
        }

        this.$emit('updateTable', _wmids);
      }
    },
    setContextFocused() {
      this.isContextFocused = true;
      this.currentEditing = null;
    },

    setContextUnfocused() {
      this.isContextFocused = false;
      this.currentEditing = null;
    },
    changeDate(first, second) {
      this.startDate = first += ' 00:00:00';
      if (second) {
        this.endDate = second += ' 00:00:00';
      } else {
        this.endDate = null;
      }
    },
    changeGlobalDate(date) {
      if (!this.date) {
        this.date = date.toISOString();
      } else {
        this.secondDate = date.toISOString();
      }
    },
    submitDate() {
      const _first = this.formatDate(this.date);
      const _second = this.formatDate(this.secondDate);

      this.datePlaceholder = `${_first} - ${_second ? _second : 'Бессрочен'}`;

      this.startDate = _first;
      this.endDate = _second;

      this.date = null;
      this.secondDate = null;
    },
    changeWmGlobalDate(date) {
      if (!this.wmDate) {
        this.wmDate = date.toISOString();
      } else {
        this.secondWmDate = date.toISOString();
      }
    },

    submitWmDate() {
      const _first = this.formatDate(this.wmDate);
      const _second = this.formatDate(this.secondWmDate);

      this.wmDatePlaceholder = `${_first} - ${_second ? _second : 'Бессрочен'}`;

      this.startWmDate = _first;
      this.endWmDate = _second;

      this.wmDate = null;
      this.secondWmDate = null;
    },
    formatDate(date) {
      if (!date) return null;
      const _date = new Date(Date.parse(date));
      const year = _date.getFullYear();
      const month = String(_date.getMonth() + 1).padStart(2, '0');
      const day = String(_date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    async updateAll() {
      if (!this.startDate && !this.bet) return;
      const _wmids = [] as any[];
      const _items = this.items
      for (const item of _items as any[]) {
        _wmids.push(item.id);
        await RCP({
          method: 'Wm.edit',
          params: {
            wmid: item.wmid,
            cost: this.bet || item.cost,
            start_date: this.startDate || item.start_date,
            end_date: this.endDate,
            id: item.id,
            partner_id: item.partner_id,
            project_id: item.project_id,
          },
        });
      }


      this.$emit('updateTable', _wmids);

    },
    openTableDownload(menuType) {
      this.openedMenu = this.openedMenu === menuType ? '' : menuType
    },
    addWebmaster() {
      if (!this.startWmDate || !this.betAdd || !this.webmasterAdd) return;
      RCP({
        method: 'Wm.add',
        params: {
          type: this.$props.items[0].type as any,
          wmid: this.webmasterAdd,
          cost: this.betAdd,
          start_date: this.startWmDate,
          end_date: this.endWmDate,
          partner_id: this.partnerId,
          project_id: this.projectId,
        },
      }).then((result) => {
        const _wmids = [] as any[];
        _wmids.push(result.id)
        const _items = this.items
        for (const item of _items as any[]) {
          _wmids.push(item.id);
        }
        this.$emit('updateTable', _wmids, [result.id, this.webmasterAdd]);
      });
    }
  },
  watch: {
    items(val) {
      this.localData = [...val];
      this.filtered();
    },
  },
});
