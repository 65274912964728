

























































































import partners from '@/views/Partners.vue';
import Vue from 'vue';

import RCP from '@/api/RCP.ts';

import ViewChart from '@/components/statistics/Chart/View.vue';
import DatePicker from '@/components/DatePicker.vue';
import SelectField from '@/components/SelectField.vue';

import channel from '@/helpers/const/channel';

import { ErrorResponse } from '@/types/Errors';
import ManageCostsTable from '@/components/manageCosts/ManageCostsTable.vue';
import DropdownCheckbox from '@/components/manageCosts/DropdownCheckbox.vue';

export default Vue.extend({
  name: 'Statistics',
  data() {
    const today = new Date();
    const monthCalc = today.getMonth();
    const year = today.getFullYear();
    const month = ('0' + (monthCalc)).slice(-2);
    const day = ('0' + '1').slice(-2);
    let secondYear = year;
    let secondMonth = ('0' + (monthCalc + 1)).slice(-2);

    if (month === '12') {
      secondYear += 1;
      secondMonth = '01';
    }

    return {
      chart: {} as any,
      table: [] as any,
      tableTotal: {} as any,
      projectsMap: new Map(),
      projects: [] as any,
      partners: [] as any,
      options: {
        status: {
          new: 'Первичники',
          old: 'Повторники',
        },
        source: {
          all: 'Все',
          new_profile: 'Новые',
          old_profile: 'Старые',
        },
        channel,
      },
      params: {
        project_id: 1,
        partner_code: 'all',
        wmids: '1',
      },
      wmidsToDisplay: [] as any[],
      selectedWmids: ['1'],
      selectedWmidsNames: [] as any[],
      partnerId: 0,
      projectTitle: '',
      partnerTitle: '',
      error: '',
      request: false,
      datesError: false,
      datesWatcher: () => {
        return;
      },
      oneDay: false,
    };
  },
  created() {
    this.getFilters().then(this.getResults);
  },
  watch: {
    'projects'(val: any) {
      if (val.length === 0) {
        return;
      }
      this.params.project_id = val[0].id;
      this.projectTitle = val[0].title;


      this.projectsMap = new Map(val.map((element: any) => {
        return [element.id, element.partners];
      }));

      this.partners = this.projectsMap.get(val[0].id);
      this.params.partner_code = this.projectsMap.get(val[0].id)[0].code;
    },
    'params.project_id'(val: any) {
      this.params.partner_code = this.projectsMap.get(val)[0].code;
      this.partners = this.projectsMap.get(val);
    },
    'params.partner_code'(val: any) {
      this.partnerId = this.partners.findIndex(item => item.code === val);
      this.selectedWmids = [];
      this.wmidsToDisplay = this.partners[this.partnerId].wmids
    },
    'selectedWmids'() {
      const partner = this.partners[this.partnerId];
      this.selectedWmidsNames = this.selectedWmids.map((id) => {
        const _index = partner.wmids.findIndex(wmid => wmid.id === id);
        return partner.wmids[_index].wmid;
      });
      this.params.wmids = this.selectedWmids.join(',');
    },
  },
  methods: {
    validate() {

      let valid = true;

      if (valid) {
        this.getResults();
      } else {
        this.datesErrorActivate();
      }
    },
    datesErrorActivate() {
      this.datesError = true;
      this.datesWatcher = this.$watch('params', () => {
        this.datesError = false;
        this.datesWatcher();
      }, {
        deep: true,
      });
    },
    getFilters() {
      return new Promise((resolve) => {
        RCP({
          method: 'Filter.mc',
          id: 'getFilters',
        }).then((result: any) => {
          this.projects = result;
        }).catch((error: ErrorResponse) => {
          this.error = error.message;
          this.request = false;
        });
        resolve();
      });
    },
    getResults() {
      if (this.selectedWmids.length < 1) return;
      if (!this.request) {
        this.request = true;
        this.error = '';

        this.table = [] as any;
        this.chart = [];

        this.oneDay = false;
        RCP({
          method: 'Data.mc',
          params: this.params,
          id: 'getResults',
        }).then((result: any) => {

          this.chart = result.chart;
          this.table = result;
          this.tableTotal = result.sum;
          this.request = false;


          this.addActions();
        }).catch((error: ErrorResponse) => {
          this.error = error.message;
          this.request = false;
        });
      }
    },
    addActions() {
      for (let i = 0; i < this.table.length; i++) {
        (this.table[i] as any).actions = ' ';
      }
    },
    selectAllWmids() {
      const wmids = this.partners[this.partnerId].wmids;
      this.selectedWmids = [];
      for (const wmid of wmids) {
        this.selectedWmids.push(String(wmid.id));
        // this.selectedWmidsNames.push(String(wmid.wmid));
      }
    },
    updateTable(wmids, wmidToAdd) {
      this.selectedWmids = wmids;
      this.getResults();
      this.wmidsToDisplay.push({
        id: wmidToAdd[0],
        wmid: wmidToAdd[1]
      })
    },
  },
  components: {
    DropdownCheckbox,
    ViewChart,
    ManageCostsTable,
    DatePicker,
    SelectField,

  },
});
