
import Vue from 'vue';

export default Vue.extend({
  name: 'DropdownCheckbox',
  props: ['modelValue', 'name', 'wmid'],
  // emits: ['update:modelValue'],
  data() {
    return {
      hidden: true,
    };
  },
  methods: {
    clickOutside() {
      this.hidden = true;
    },
  },
});
